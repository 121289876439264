.pf-u-w-auto {
  width: auto !important;
}

.pf-u-w-initial {
  width: initial !important;
}

.pf-u-w-inherit {
  width: inherit !important;
}

.pf-u-w-0 {
  width: 0% !important;
}

.pf-u-w-25 {
  width: 25% !important;
}

.pf-u-w-33 {
  width: 33.3333333333% !important;
}

.pf-u-w-50 {
  width: 50% !important;
}

.pf-u-w-66 {
  width: 66.6666666667% !important;
}

.pf-u-w-75 {
  width: 75% !important;
}

.pf-u-w-100 {
  width: 100% !important;
}

.pf-u-w-25vw {
  width: 25vw !important;
}

.pf-u-w-33vw {
  width: 33.3333333333vw !important;
}

.pf-u-w-50vw {
  width: 50vw !important;
}

.pf-u-w-66vw {
  width: 66.6666666667vw !important;
}

.pf-u-w-75vw {
  width: 75vw !important;
}

.pf-u-w-100vw {
  width: 100vw !important;
}

@media screen and (min-width: 576px) {
  .pf-u-w-auto-on-sm {
    width: auto !important;
  }
  .pf-u-w-initial-on-sm {
    width: initial !important;
  }
  .pf-u-w-inherit-on-sm {
    width: inherit !important;
  }
  .pf-u-w-0-on-sm {
    width: 0% !important;
  }
  .pf-u-w-25-on-sm {
    width: 25% !important;
  }
  .pf-u-w-33-on-sm {
    width: 33.3333333333% !important;
  }
  .pf-u-w-50-on-sm {
    width: 50% !important;
  }
  .pf-u-w-66-on-sm {
    width: 66.6666666667% !important;
  }
  .pf-u-w-75-on-sm {
    width: 75% !important;
  }
  .pf-u-w-100-on-sm {
    width: 100% !important;
  }
  .pf-u-w-25vw-on-sm {
    width: 25vw !important;
  }
  .pf-u-w-33vw-on-sm {
    width: 33.3333333333vw !important;
  }
  .pf-u-w-50vw-on-sm {
    width: 50vw !important;
  }
  .pf-u-w-66vw-on-sm {
    width: 66.6666666667vw !important;
  }
  .pf-u-w-75vw-on-sm {
    width: 75vw !important;
  }
  .pf-u-w-100vw-on-sm {
    width: 100vw !important;
  }
}
@media screen and (min-width: 768px) {
  .pf-u-w-auto-on-md {
    width: auto !important;
  }
  .pf-u-w-initial-on-md {
    width: initial !important;
  }
  .pf-u-w-inherit-on-md {
    width: inherit !important;
  }
  .pf-u-w-0-on-md {
    width: 0% !important;
  }
  .pf-u-w-25-on-md {
    width: 25% !important;
  }
  .pf-u-w-33-on-md {
    width: 33.3333333333% !important;
  }
  .pf-u-w-50-on-md {
    width: 50% !important;
  }
  .pf-u-w-66-on-md {
    width: 66.6666666667% !important;
  }
  .pf-u-w-75-on-md {
    width: 75% !important;
  }
  .pf-u-w-100-on-md {
    width: 100% !important;
  }
  .pf-u-w-25vw-on-md {
    width: 25vw !important;
  }
  .pf-u-w-33vw-on-md {
    width: 33.3333333333vw !important;
  }
  .pf-u-w-50vw-on-md {
    width: 50vw !important;
  }
  .pf-u-w-66vw-on-md {
    width: 66.6666666667vw !important;
  }
  .pf-u-w-75vw-on-md {
    width: 75vw !important;
  }
  .pf-u-w-100vw-on-md {
    width: 100vw !important;
  }
}
@media screen and (min-width: 992px) {
  .pf-u-w-auto-on-lg {
    width: auto !important;
  }
  .pf-u-w-initial-on-lg {
    width: initial !important;
  }
  .pf-u-w-inherit-on-lg {
    width: inherit !important;
  }
  .pf-u-w-0-on-lg {
    width: 0% !important;
  }
  .pf-u-w-25-on-lg {
    width: 25% !important;
  }
  .pf-u-w-33-on-lg {
    width: 33.3333333333% !important;
  }
  .pf-u-w-50-on-lg {
    width: 50% !important;
  }
  .pf-u-w-66-on-lg {
    width: 66.6666666667% !important;
  }
  .pf-u-w-75-on-lg {
    width: 75% !important;
  }
  .pf-u-w-100-on-lg {
    width: 100% !important;
  }
  .pf-u-w-25vw-on-lg {
    width: 25vw !important;
  }
  .pf-u-w-33vw-on-lg {
    width: 33.3333333333vw !important;
  }
  .pf-u-w-50vw-on-lg {
    width: 50vw !important;
  }
  .pf-u-w-66vw-on-lg {
    width: 66.6666666667vw !important;
  }
  .pf-u-w-75vw-on-lg {
    width: 75vw !important;
  }
  .pf-u-w-100vw-on-lg {
    width: 100vw !important;
  }
}
@media screen and (min-width: 1200px) {
  .pf-u-w-auto-on-xl {
    width: auto !important;
  }
  .pf-u-w-initial-on-xl {
    width: initial !important;
  }
  .pf-u-w-inherit-on-xl {
    width: inherit !important;
  }
  .pf-u-w-0-on-xl {
    width: 0% !important;
  }
  .pf-u-w-25-on-xl {
    width: 25% !important;
  }
  .pf-u-w-33-on-xl {
    width: 33.3333333333% !important;
  }
  .pf-u-w-50-on-xl {
    width: 50% !important;
  }
  .pf-u-w-66-on-xl {
    width: 66.6666666667% !important;
  }
  .pf-u-w-75-on-xl {
    width: 75% !important;
  }
  .pf-u-w-100-on-xl {
    width: 100% !important;
  }
  .pf-u-w-25vw-on-xl {
    width: 25vw !important;
  }
  .pf-u-w-33vw-on-xl {
    width: 33.3333333333vw !important;
  }
  .pf-u-w-50vw-on-xl {
    width: 50vw !important;
  }
  .pf-u-w-66vw-on-xl {
    width: 66.6666666667vw !important;
  }
  .pf-u-w-75vw-on-xl {
    width: 75vw !important;
  }
  .pf-u-w-100vw-on-xl {
    width: 100vw !important;
  }
}
@media screen and (min-width: 1450px) {
  .pf-u-w-auto-on-2xl {
    width: auto !important;
  }
  .pf-u-w-initial-on-2xl {
    width: initial !important;
  }
  .pf-u-w-inherit-on-2xl {
    width: inherit !important;
  }
  .pf-u-w-0-on-2xl {
    width: 0% !important;
  }
  .pf-u-w-25-on-2xl {
    width: 25% !important;
  }
  .pf-u-w-33-on-2xl {
    width: 33.3333333333% !important;
  }
  .pf-u-w-50-on-2xl {
    width: 50% !important;
  }
  .pf-u-w-66-on-2xl {
    width: 66.6666666667% !important;
  }
  .pf-u-w-75-on-2xl {
    width: 75% !important;
  }
  .pf-u-w-100-on-2xl {
    width: 100% !important;
  }
  .pf-u-w-25vw-on-2xl {
    width: 25vw !important;
  }
  .pf-u-w-33vw-on-2xl {
    width: 33.3333333333vw !important;
  }
  .pf-u-w-50vw-on-2xl {
    width: 50vw !important;
  }
  .pf-u-w-66vw-on-2xl {
    width: 66.6666666667vw !important;
  }
  .pf-u-w-75vw-on-2xl {
    width: 75vw !important;
  }
  .pf-u-w-100vw-on-2xl {
    width: 100vw !important;
  }
}
.pf-u-h-auto {
  height: auto !important;
}

.pf-u-h-initial {
  height: initial !important;
}

.pf-u-h-inherit {
  height: inherit !important;
}

.pf-u-h-0 {
  height: 0% !important;
}

.pf-u-h-25 {
  height: 25% !important;
}

.pf-u-h-33 {
  height: 33.3333333333% !important;
}

.pf-u-h-50 {
  height: 50% !important;
}

.pf-u-h-66 {
  height: 66.6666666667% !important;
}

.pf-u-h-75 {
  height: 75% !important;
}

.pf-u-h-100 {
  height: 100% !important;
}

.pf-u-h-25vh {
  height: 25vh !important;
}

.pf-u-h-33vh {
  height: 33.3333333333vh !important;
}

.pf-u-h-50vh {
  height: 50vh !important;
}

.pf-u-h-66vh {
  height: 66.6666666667vh !important;
}

.pf-u-h-75vh {
  height: 75vh !important;
}

.pf-u-h-100vh {
  height: 100vh !important;
}

@media screen and (min-width: 576px) {
  .pf-u-h-auto-on-sm {
    height: auto !important;
  }
  .pf-u-h-initial-on-sm {
    height: initial !important;
  }
  .pf-u-h-inherit-on-sm {
    height: inherit !important;
  }
  .pf-u-h-0-on-sm {
    height: 0% !important;
  }
  .pf-u-h-25-on-sm {
    height: 25% !important;
  }
  .pf-u-h-33-on-sm {
    height: 33.3333333333% !important;
  }
  .pf-u-h-50-on-sm {
    height: 50% !important;
  }
  .pf-u-h-66-on-sm {
    height: 66.6666666667% !important;
  }
  .pf-u-h-75-on-sm {
    height: 75% !important;
  }
  .pf-u-h-100-on-sm {
    height: 100% !important;
  }
  .pf-u-h-25vh-on-sm {
    height: 25vh !important;
  }
  .pf-u-h-33vh-on-sm {
    height: 33.3333333333vh !important;
  }
  .pf-u-h-50vh-on-sm {
    height: 50vh !important;
  }
  .pf-u-h-66vh-on-sm {
    height: 66.6666666667vh !important;
  }
  .pf-u-h-75vh-on-sm {
    height: 75vh !important;
  }
  .pf-u-h-100vh-on-sm {
    height: 100vh !important;
  }
}
@media screen and (min-width: 768px) {
  .pf-u-h-auto-on-md {
    height: auto !important;
  }
  .pf-u-h-initial-on-md {
    height: initial !important;
  }
  .pf-u-h-inherit-on-md {
    height: inherit !important;
  }
  .pf-u-h-0-on-md {
    height: 0% !important;
  }
  .pf-u-h-25-on-md {
    height: 25% !important;
  }
  .pf-u-h-33-on-md {
    height: 33.3333333333% !important;
  }
  .pf-u-h-50-on-md {
    height: 50% !important;
  }
  .pf-u-h-66-on-md {
    height: 66.6666666667% !important;
  }
  .pf-u-h-75-on-md {
    height: 75% !important;
  }
  .pf-u-h-100-on-md {
    height: 100% !important;
  }
  .pf-u-h-25vh-on-md {
    height: 25vh !important;
  }
  .pf-u-h-33vh-on-md {
    height: 33.3333333333vh !important;
  }
  .pf-u-h-50vh-on-md {
    height: 50vh !important;
  }
  .pf-u-h-66vh-on-md {
    height: 66.6666666667vh !important;
  }
  .pf-u-h-75vh-on-md {
    height: 75vh !important;
  }
  .pf-u-h-100vh-on-md {
    height: 100vh !important;
  }
}
@media screen and (min-width: 992px) {
  .pf-u-h-auto-on-lg {
    height: auto !important;
  }
  .pf-u-h-initial-on-lg {
    height: initial !important;
  }
  .pf-u-h-inherit-on-lg {
    height: inherit !important;
  }
  .pf-u-h-0-on-lg {
    height: 0% !important;
  }
  .pf-u-h-25-on-lg {
    height: 25% !important;
  }
  .pf-u-h-33-on-lg {
    height: 33.3333333333% !important;
  }
  .pf-u-h-50-on-lg {
    height: 50% !important;
  }
  .pf-u-h-66-on-lg {
    height: 66.6666666667% !important;
  }
  .pf-u-h-75-on-lg {
    height: 75% !important;
  }
  .pf-u-h-100-on-lg {
    height: 100% !important;
  }
  .pf-u-h-25vh-on-lg {
    height: 25vh !important;
  }
  .pf-u-h-33vh-on-lg {
    height: 33.3333333333vh !important;
  }
  .pf-u-h-50vh-on-lg {
    height: 50vh !important;
  }
  .pf-u-h-66vh-on-lg {
    height: 66.6666666667vh !important;
  }
  .pf-u-h-75vh-on-lg {
    height: 75vh !important;
  }
  .pf-u-h-100vh-on-lg {
    height: 100vh !important;
  }
}
@media screen and (min-width: 1200px) {
  .pf-u-h-auto-on-xl {
    height: auto !important;
  }
  .pf-u-h-initial-on-xl {
    height: initial !important;
  }
  .pf-u-h-inherit-on-xl {
    height: inherit !important;
  }
  .pf-u-h-0-on-xl {
    height: 0% !important;
  }
  .pf-u-h-25-on-xl {
    height: 25% !important;
  }
  .pf-u-h-33-on-xl {
    height: 33.3333333333% !important;
  }
  .pf-u-h-50-on-xl {
    height: 50% !important;
  }
  .pf-u-h-66-on-xl {
    height: 66.6666666667% !important;
  }
  .pf-u-h-75-on-xl {
    height: 75% !important;
  }
  .pf-u-h-100-on-xl {
    height: 100% !important;
  }
  .pf-u-h-25vh-on-xl {
    height: 25vh !important;
  }
  .pf-u-h-33vh-on-xl {
    height: 33.3333333333vh !important;
  }
  .pf-u-h-50vh-on-xl {
    height: 50vh !important;
  }
  .pf-u-h-66vh-on-xl {
    height: 66.6666666667vh !important;
  }
  .pf-u-h-75vh-on-xl {
    height: 75vh !important;
  }
  .pf-u-h-100vh-on-xl {
    height: 100vh !important;
  }
}
@media screen and (min-width: 1450px) {
  .pf-u-h-auto-on-2xl {
    height: auto !important;
  }
  .pf-u-h-initial-on-2xl {
    height: initial !important;
  }
  .pf-u-h-inherit-on-2xl {
    height: inherit !important;
  }
  .pf-u-h-0-on-2xl {
    height: 0% !important;
  }
  .pf-u-h-25-on-2xl {
    height: 25% !important;
  }
  .pf-u-h-33-on-2xl {
    height: 33.3333333333% !important;
  }
  .pf-u-h-50-on-2xl {
    height: 50% !important;
  }
  .pf-u-h-66-on-2xl {
    height: 66.6666666667% !important;
  }
  .pf-u-h-75-on-2xl {
    height: 75% !important;
  }
  .pf-u-h-100-on-2xl {
    height: 100% !important;
  }
  .pf-u-h-25vh-on-2xl {
    height: 25vh !important;
  }
  .pf-u-h-33vh-on-2xl {
    height: 33.3333333333vh !important;
  }
  .pf-u-h-50vh-on-2xl {
    height: 50vh !important;
  }
  .pf-u-h-66vh-on-2xl {
    height: 66.6666666667vh !important;
  }
  .pf-u-h-75vh-on-2xl {
    height: 75vh !important;
  }
  .pf-u-h-100vh-on-2xl {
    height: 100vh !important;
  }
}
.pf-u-min-width {
  --pf-u-min-width--MinWidth: 0;
  min-width: var(--pf-u-min-width--MinWidth) !important;
}
@media (min-width: 576px) {
  .pf-u-min-width {
    min-width: var(--pf-u-min-width--MinWidth-on-sm, var(--pf-u-min-width--MinWidth)) !important;
  }
}
@media (min-width: 768px) {
  .pf-u-min-width {
    min-width: var(--pf-u-min-width--MinWidth-on-md, var(--pf-u-min-width--MinWidth-on-sm, var(--pf-u-min-width--MinWidth))) !important;
  }
}
@media (min-width: 992px) {
  .pf-u-min-width {
    min-width: var(--pf-u-min-width--MinWidth-on-lg, var(--pf-u-min-width--MinWidth-on-md, var(--pf-u-min-width--MinWidth-on-sm, var(--pf-u-min-width--MinWidth)))) !important;
  }
}
@media (min-width: 1200px) {
  .pf-u-min-width {
    min-width: var(--pf-u-min-width--MinWidth-on-xl, var(--pf-u-min-width--MinWidth-on-lg, var(--pf-u-min-width--MinWidth-on-md, var(--pf-u-min-width--MinWidth-on-sm, var(--pf-u-min-width--MinWidth))))) !important;
  }
}
@media (min-width: 1450px) {
  .pf-u-min-width {
    min-width: var(--pf-u-min-width--MinWidth-on-2xl, var(--pf-u-min-width--MinWidth-on-xl, var(--pf-u-min-width--MinWidth-on-lg, var(--pf-u-min-width--MinWidth-on-md, var(--pf-u-min-width--MinWidth-on-sm, var(--pf-u-min-width--MinWidth)))))) !important;
  }
}

.pf-u-max-width {
  --pf-u-max-width--MaxWidth: auto;
  max-width: var(--pf-u-max-width--MaxWidth) !important;
}
@media (min-width: 576px) {
  .pf-u-max-width {
    max-width: var(--pf-u-max-width--MaxWidth-on-sm, var(--pf-u-max-width--MaxWidth)) !important;
  }
}
@media (min-width: 768px) {
  .pf-u-max-width {
    max-width: var(--pf-u-max-width--MaxWidth-on-md, var(--pf-u-max-width--MaxWidth-on-sm, var(--pf-u-max-width--MaxWidth))) !important;
  }
}
@media (min-width: 992px) {
  .pf-u-max-width {
    max-width: var(--pf-u-max-width--MaxWidth-on-lg, var(--pf-u-max-width--MaxWidth-on-md, var(--pf-u-max-width--MaxWidth-on-sm, var(--pf-u-max-width--MaxWidth)))) !important;
  }
}
@media (min-width: 1200px) {
  .pf-u-max-width {
    max-width: var(--pf-u-max-width--MaxWidth-on-xl, var(--pf-u-max-width--MaxWidth-on-lg, var(--pf-u-max-width--MaxWidth-on-md, var(--pf-u-max-width--MaxWidth-on-sm, var(--pf-u-max-width--MaxWidth))))) !important;
  }
}
@media (min-width: 1450px) {
  .pf-u-max-width {
    max-width: var(--pf-u-max-width--MaxWidth-on-2xl, var(--pf-u-max-width--MaxWidth-on-xl, var(--pf-u-max-width--MaxWidth-on-lg, var(--pf-u-max-width--MaxWidth-on-md, var(--pf-u-max-width--MaxWidth-on-sm, var(--pf-u-max-width--MaxWidth)))))) !important;
  }
}

.pf-u-min-height {
  --pf-u-min-height--MinHeight: 0;
  min-height: var(--pf-u-min-height--MinHeight) !important;
}
@media (min-width: 576px) {
  .pf-u-min-height {
    min-height: var(--pf-u-min-height--MinHeight-on-sm, var(--pf-u-min-height--MinHeight)) !important;
  }
}
@media (min-width: 768px) {
  .pf-u-min-height {
    min-height: var(--pf-u-min-height--MinHeight-on-md, var(--pf-u-min-height--MinHeight-on-sm, var(--pf-u-min-height--MinHeight))) !important;
  }
}
@media (min-width: 992px) {
  .pf-u-min-height {
    min-height: var(--pf-u-min-height--MinHeight-on-lg, var(--pf-u-min-height--MinHeight-on-md, var(--pf-u-min-height--MinHeight-on-sm, var(--pf-u-min-height--MinHeight)))) !important;
  }
}
@media (min-width: 1200px) {
  .pf-u-min-height {
    min-height: var(--pf-u-min-height--MinHeight-on-xl, var(--pf-u-min-height--MinHeight-on-lg, var(--pf-u-min-height--MinHeight-on-md, var(--pf-u-min-height--MinHeight-on-sm, var(--pf-u-min-height--MinHeight))))) !important;
  }
}
@media (min-width: 1450px) {
  .pf-u-min-height {
    min-height: var(--pf-u-min-height--MinHeight-on-2xl, var(--pf-u-min-height--MinHeight-on-xl, var(--pf-u-min-height--MinHeight-on-lg, var(--pf-u-min-height--MinHeight-on-md, var(--pf-u-min-height--MinHeight-on-sm, var(--pf-u-min-height--MinHeight)))))) !important;
  }
}

.pf-u-max-height {
  --pf-u-max-height--MaxHeight: auto;
  max-height: var(--pf-u-max-height--MaxHeight) !important;
}
@media (min-width: 576px) {
  .pf-u-max-height {
    max-height: var(--pf-u-max-height--MaxHeight-on-sm, var(--pf-u-max-height--MaxHeight)) !important;
  }
}
@media (min-width: 768px) {
  .pf-u-max-height {
    max-height: var(--pf-u-max-height--MaxHeight-on-md, var(--pf-u-max-height--MaxHeight-on-sm, var(--pf-u-max-height--MaxHeight))) !important;
  }
}
@media (min-width: 992px) {
  .pf-u-max-height {
    max-height: var(--pf-u-max-height--MaxHeight-on-lg, var(--pf-u-max-height--MaxHeight-on-md, var(--pf-u-max-height--MaxHeight-on-sm, var(--pf-u-max-height--MaxHeight)))) !important;
  }
}
@media (min-width: 1200px) {
  .pf-u-max-height {
    max-height: var(--pf-u-max-height--MaxHeight-on-xl, var(--pf-u-max-height--MaxHeight-on-lg, var(--pf-u-max-height--MaxHeight-on-md, var(--pf-u-max-height--MaxHeight-on-sm, var(--pf-u-max-height--MaxHeight))))) !important;
  }
}
@media (min-width: 1450px) {
  .pf-u-max-height {
    max-height: var(--pf-u-max-height--MaxHeight-on-2xl, var(--pf-u-max-height--MaxHeight-on-xl, var(--pf-u-max-height--MaxHeight-on-lg, var(--pf-u-max-height--MaxHeight-on-md, var(--pf-u-max-height--MaxHeight-on-sm, var(--pf-u-max-height--MaxHeight)))))) !important;
  }
}