.pf-u-m-auto {
  margin: auto !important;
}

.pf-u-m-0 {
  margin: 0 !important;
}

.pf-u-m-xs {
  margin: var(--pf-global--spacer--xs) !important;
}

.pf-u-m-sm {
  margin: var(--pf-global--spacer--sm) !important;
}

.pf-u-m-md {
  margin: var(--pf-global--spacer--md) !important;
}

.pf-u-m-lg {
  margin: var(--pf-global--spacer--lg) !important;
}

.pf-u-m-xl {
  margin: var(--pf-global--spacer--xl) !important;
}

.pf-u-m-2xl {
  margin: var(--pf-global--spacer--2xl) !important;
}

.pf-u-m-3xl {
  margin: var(--pf-global--spacer--3xl) !important;
}

.pf-u-m-4xl {
  margin: var(--pf-global--spacer--4xl) !important;
}

.pf-u-mt-auto {
  margin-top: auto !important;
}

.pf-u-mt-0 {
  margin-top: 0 !important;
}

.pf-u-mt-xs {
  margin-top: var(--pf-global--spacer--xs) !important;
}

.pf-u-mt-sm {
  margin-top: var(--pf-global--spacer--sm) !important;
}

.pf-u-mt-md {
  margin-top: var(--pf-global--spacer--md) !important;
}

.pf-u-mt-lg {
  margin-top: var(--pf-global--spacer--lg) !important;
}

.pf-u-mt-xl {
  margin-top: var(--pf-global--spacer--xl) !important;
}

.pf-u-mt-2xl {
  margin-top: var(--pf-global--spacer--2xl) !important;
}

.pf-u-mt-3xl {
  margin-top: var(--pf-global--spacer--3xl) !important;
}

.pf-u-mt-4xl {
  margin-top: var(--pf-global--spacer--4xl) !important;
}

.pf-u-mr-auto {
  margin-right: auto !important;
}

.pf-u-mr-0 {
  margin-right: 0 !important;
}

.pf-u-mr-xs {
  margin-right: var(--pf-global--spacer--xs) !important;
}

.pf-u-mr-sm {
  margin-right: var(--pf-global--spacer--sm) !important;
}

.pf-u-mr-md {
  margin-right: var(--pf-global--spacer--md) !important;
}

.pf-u-mr-lg {
  margin-right: var(--pf-global--spacer--lg) !important;
}

.pf-u-mr-xl {
  margin-right: var(--pf-global--spacer--xl) !important;
}

.pf-u-mr-2xl {
  margin-right: var(--pf-global--spacer--2xl) !important;
}

.pf-u-mr-3xl {
  margin-right: var(--pf-global--spacer--3xl) !important;
}

.pf-u-mr-4xl {
  margin-right: var(--pf-global--spacer--4xl) !important;
}

.pf-u-mb-auto {
  margin-bottom: auto !important;
}

.pf-u-mb-0 {
  margin-bottom: 0 !important;
}

.pf-u-mb-xs {
  margin-bottom: var(--pf-global--spacer--xs) !important;
}

.pf-u-mb-sm {
  margin-bottom: var(--pf-global--spacer--sm) !important;
}

.pf-u-mb-md {
  margin-bottom: var(--pf-global--spacer--md) !important;
}

.pf-u-mb-lg {
  margin-bottom: var(--pf-global--spacer--lg) !important;
}

.pf-u-mb-xl {
  margin-bottom: var(--pf-global--spacer--xl) !important;
}

.pf-u-mb-2xl {
  margin-bottom: var(--pf-global--spacer--2xl) !important;
}

.pf-u-mb-3xl {
  margin-bottom: var(--pf-global--spacer--3xl) !important;
}

.pf-u-mb-4xl {
  margin-bottom: var(--pf-global--spacer--4xl) !important;
}

.pf-u-ml-auto {
  margin-left: auto !important;
}

.pf-u-ml-0 {
  margin-left: 0 !important;
}

.pf-u-ml-xs {
  margin-left: var(--pf-global--spacer--xs) !important;
}

.pf-u-ml-sm {
  margin-left: var(--pf-global--spacer--sm) !important;
}

.pf-u-ml-md {
  margin-left: var(--pf-global--spacer--md) !important;
}

.pf-u-ml-lg {
  margin-left: var(--pf-global--spacer--lg) !important;
}

.pf-u-ml-xl {
  margin-left: var(--pf-global--spacer--xl) !important;
}

.pf-u-ml-2xl {
  margin-left: var(--pf-global--spacer--2xl) !important;
}

.pf-u-ml-3xl {
  margin-left: var(--pf-global--spacer--3xl) !important;
}

.pf-u-ml-4xl {
  margin-left: var(--pf-global--spacer--4xl) !important;
}

.pf-u-mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.pf-u-mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.pf-u-mx-xs {
  margin-right: var(--pf-global--spacer--xs) !important;
  margin-left: var(--pf-global--spacer--xs) !important;
}

.pf-u-mx-sm {
  margin-right: var(--pf-global--spacer--sm) !important;
  margin-left: var(--pf-global--spacer--sm) !important;
}

.pf-u-mx-md {
  margin-right: var(--pf-global--spacer--md) !important;
  margin-left: var(--pf-global--spacer--md) !important;
}

.pf-u-mx-lg {
  margin-right: var(--pf-global--spacer--lg) !important;
  margin-left: var(--pf-global--spacer--lg) !important;
}

.pf-u-mx-xl {
  margin-right: var(--pf-global--spacer--xl) !important;
  margin-left: var(--pf-global--spacer--xl) !important;
}

.pf-u-mx-2xl {
  margin-right: var(--pf-global--spacer--2xl) !important;
  margin-left: var(--pf-global--spacer--2xl) !important;
}

.pf-u-mx-3xl {
  margin-right: var(--pf-global--spacer--3xl) !important;
  margin-left: var(--pf-global--spacer--3xl) !important;
}

.pf-u-mx-4xl {
  margin-right: var(--pf-global--spacer--4xl) !important;
  margin-left: var(--pf-global--spacer--4xl) !important;
}

.pf-u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.pf-u-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.pf-u-my-xs {
  margin-top: var(--pf-global--spacer--xs) !important;
  margin-bottom: var(--pf-global--spacer--xs) !important;
}

.pf-u-my-sm {
  margin-top: var(--pf-global--spacer--sm) !important;
  margin-bottom: var(--pf-global--spacer--sm) !important;
}

.pf-u-my-md {
  margin-top: var(--pf-global--spacer--md) !important;
  margin-bottom: var(--pf-global--spacer--md) !important;
}

.pf-u-my-lg {
  margin-top: var(--pf-global--spacer--lg) !important;
  margin-bottom: var(--pf-global--spacer--lg) !important;
}

.pf-u-my-xl {
  margin-top: var(--pf-global--spacer--xl) !important;
  margin-bottom: var(--pf-global--spacer--xl) !important;
}

.pf-u-my-2xl {
  margin-top: var(--pf-global--spacer--2xl) !important;
  margin-bottom: var(--pf-global--spacer--2xl) !important;
}

.pf-u-my-3xl {
  margin-top: var(--pf-global--spacer--3xl) !important;
  margin-bottom: var(--pf-global--spacer--3xl) !important;
}

.pf-u-my-4xl {
  margin-top: var(--pf-global--spacer--4xl) !important;
  margin-bottom: var(--pf-global--spacer--4xl) !important;
}

.pf-u-p-auto {
  padding: auto !important;
}

.pf-u-p-0 {
  padding: 0 !important;
}

.pf-u-p-xs {
  padding: var(--pf-global--spacer--xs) !important;
}

.pf-u-p-sm {
  padding: var(--pf-global--spacer--sm) !important;
}

.pf-u-p-md {
  padding: var(--pf-global--spacer--md) !important;
}

.pf-u-p-lg {
  padding: var(--pf-global--spacer--lg) !important;
}

.pf-u-p-xl {
  padding: var(--pf-global--spacer--xl) !important;
}

.pf-u-p-2xl {
  padding: var(--pf-global--spacer--2xl) !important;
}

.pf-u-p-3xl {
  padding: var(--pf-global--spacer--3xl) !important;
}

.pf-u-p-4xl {
  padding: var(--pf-global--spacer--4xl) !important;
}

.pf-u-pt-auto {
  padding-top: auto !important;
}

.pf-u-pt-0 {
  padding-top: 0 !important;
}

.pf-u-pt-xs {
  padding-top: var(--pf-global--spacer--xs) !important;
}

.pf-u-pt-sm {
  padding-top: var(--pf-global--spacer--sm) !important;
}

.pf-u-pt-md {
  padding-top: var(--pf-global--spacer--md) !important;
}

.pf-u-pt-lg {
  padding-top: var(--pf-global--spacer--lg) !important;
}

.pf-u-pt-xl {
  padding-top: var(--pf-global--spacer--xl) !important;
}

.pf-u-pt-2xl {
  padding-top: var(--pf-global--spacer--2xl) !important;
}

.pf-u-pt-3xl {
  padding-top: var(--pf-global--spacer--3xl) !important;
}

.pf-u-pt-4xl {
  padding-top: var(--pf-global--spacer--4xl) !important;
}

.pf-u-pr-auto {
  padding-right: auto !important;
}

.pf-u-pr-0 {
  padding-right: 0 !important;
}

.pf-u-pr-xs {
  padding-right: var(--pf-global--spacer--xs) !important;
}

.pf-u-pr-sm {
  padding-right: var(--pf-global--spacer--sm) !important;
}

.pf-u-pr-md {
  padding-right: var(--pf-global--spacer--md) !important;
}

.pf-u-pr-lg {
  padding-right: var(--pf-global--spacer--lg) !important;
}

.pf-u-pr-xl {
  padding-right: var(--pf-global--spacer--xl) !important;
}

.pf-u-pr-2xl {
  padding-right: var(--pf-global--spacer--2xl) !important;
}

.pf-u-pr-3xl {
  padding-right: var(--pf-global--spacer--3xl) !important;
}

.pf-u-pr-4xl {
  padding-right: var(--pf-global--spacer--4xl) !important;
}

.pf-u-pb-auto {
  padding-bottom: auto !important;
}

.pf-u-pb-0 {
  padding-bottom: 0 !important;
}

.pf-u-pb-xs {
  padding-bottom: var(--pf-global--spacer--xs) !important;
}

.pf-u-pb-sm {
  padding-bottom: var(--pf-global--spacer--sm) !important;
}

.pf-u-pb-md {
  padding-bottom: var(--pf-global--spacer--md) !important;
}

.pf-u-pb-lg {
  padding-bottom: var(--pf-global--spacer--lg) !important;
}

.pf-u-pb-xl {
  padding-bottom: var(--pf-global--spacer--xl) !important;
}

.pf-u-pb-2xl {
  padding-bottom: var(--pf-global--spacer--2xl) !important;
}

.pf-u-pb-3xl {
  padding-bottom: var(--pf-global--spacer--3xl) !important;
}

.pf-u-pb-4xl {
  padding-bottom: var(--pf-global--spacer--4xl) !important;
}

.pf-u-pl-auto {
  padding-left: auto !important;
}

.pf-u-pl-0 {
  padding-left: 0 !important;
}

.pf-u-pl-xs {
  padding-left: var(--pf-global--spacer--xs) !important;
}

.pf-u-pl-sm {
  padding-left: var(--pf-global--spacer--sm) !important;
}

.pf-u-pl-md {
  padding-left: var(--pf-global--spacer--md) !important;
}

.pf-u-pl-lg {
  padding-left: var(--pf-global--spacer--lg) !important;
}

.pf-u-pl-xl {
  padding-left: var(--pf-global--spacer--xl) !important;
}

.pf-u-pl-2xl {
  padding-left: var(--pf-global--spacer--2xl) !important;
}

.pf-u-pl-3xl {
  padding-left: var(--pf-global--spacer--3xl) !important;
}

.pf-u-pl-4xl {
  padding-left: var(--pf-global--spacer--4xl) !important;
}

.pf-u-px-auto {
  padding-right: auto !important;
  padding-left: auto !important;
}

.pf-u-px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.pf-u-px-xs {
  padding-right: var(--pf-global--spacer--xs) !important;
  padding-left: var(--pf-global--spacer--xs) !important;
}

.pf-u-px-sm {
  padding-right: var(--pf-global--spacer--sm) !important;
  padding-left: var(--pf-global--spacer--sm) !important;
}

.pf-u-px-md {
  padding-right: var(--pf-global--spacer--md) !important;
  padding-left: var(--pf-global--spacer--md) !important;
}

.pf-u-px-lg {
  padding-right: var(--pf-global--spacer--lg) !important;
  padding-left: var(--pf-global--spacer--lg) !important;
}

.pf-u-px-xl {
  padding-right: var(--pf-global--spacer--xl) !important;
  padding-left: var(--pf-global--spacer--xl) !important;
}

.pf-u-px-2xl {
  padding-right: var(--pf-global--spacer--2xl) !important;
  padding-left: var(--pf-global--spacer--2xl) !important;
}

.pf-u-px-3xl {
  padding-right: var(--pf-global--spacer--3xl) !important;
  padding-left: var(--pf-global--spacer--3xl) !important;
}

.pf-u-px-4xl {
  padding-right: var(--pf-global--spacer--4xl) !important;
  padding-left: var(--pf-global--spacer--4xl) !important;
}

.pf-u-py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.pf-u-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pf-u-py-xs {
  padding-top: var(--pf-global--spacer--xs) !important;
  padding-bottom: var(--pf-global--spacer--xs) !important;
}

.pf-u-py-sm {
  padding-top: var(--pf-global--spacer--sm) !important;
  padding-bottom: var(--pf-global--spacer--sm) !important;
}

.pf-u-py-md {
  padding-top: var(--pf-global--spacer--md) !important;
  padding-bottom: var(--pf-global--spacer--md) !important;
}

.pf-u-py-lg {
  padding-top: var(--pf-global--spacer--lg) !important;
  padding-bottom: var(--pf-global--spacer--lg) !important;
}

.pf-u-py-xl {
  padding-top: var(--pf-global--spacer--xl) !important;
  padding-bottom: var(--pf-global--spacer--xl) !important;
}

.pf-u-py-2xl {
  padding-top: var(--pf-global--spacer--2xl) !important;
  padding-bottom: var(--pf-global--spacer--2xl) !important;
}

.pf-u-py-3xl {
  padding-top: var(--pf-global--spacer--3xl) !important;
  padding-bottom: var(--pf-global--spacer--3xl) !important;
}

.pf-u-py-4xl {
  padding-top: var(--pf-global--spacer--4xl) !important;
  padding-bottom: var(--pf-global--spacer--4xl) !important;
}

@media screen and (min-width: 576px) {
  .pf-u-m-auto-on-sm {
    margin: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-m-0-on-sm {
    margin: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-m-xs-on-sm {
    margin: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-m-sm-on-sm {
    margin: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-m-md-on-sm {
    margin: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-m-lg-on-sm {
    margin: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-m-xl-on-sm {
    margin: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-m-2xl-on-sm {
    margin: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-m-3xl-on-sm {
    margin: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-m-4xl-on-sm {
    margin: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mt-auto-on-sm {
    margin-top: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mt-0-on-sm {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mt-xs-on-sm {
    margin-top: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mt-sm-on-sm {
    margin-top: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mt-md-on-sm {
    margin-top: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mt-lg-on-sm {
    margin-top: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mt-xl-on-sm {
    margin-top: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mt-2xl-on-sm {
    margin-top: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mt-3xl-on-sm {
    margin-top: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mt-4xl-on-sm {
    margin-top: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mr-auto-on-sm {
    margin-right: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mr-0-on-sm {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mr-xs-on-sm {
    margin-right: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mr-sm-on-sm {
    margin-right: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mr-md-on-sm {
    margin-right: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mr-lg-on-sm {
    margin-right: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mr-xl-on-sm {
    margin-right: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mr-2xl-on-sm {
    margin-right: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mr-3xl-on-sm {
    margin-right: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mr-4xl-on-sm {
    margin-right: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mb-auto-on-sm {
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mb-0-on-sm {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mb-xs-on-sm {
    margin-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mb-sm-on-sm {
    margin-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mb-md-on-sm {
    margin-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mb-lg-on-sm {
    margin-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mb-xl-on-sm {
    margin-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mb-2xl-on-sm {
    margin-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mb-3xl-on-sm {
    margin-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mb-4xl-on-sm {
    margin-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-ml-auto-on-sm {
    margin-left: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-ml-0-on-sm {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-ml-xs-on-sm {
    margin-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-ml-sm-on-sm {
    margin-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-ml-md-on-sm {
    margin-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-ml-lg-on-sm {
    margin-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-ml-xl-on-sm {
    margin-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-ml-2xl-on-sm {
    margin-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-ml-3xl-on-sm {
    margin-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-ml-4xl-on-sm {
    margin-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mx-auto-on-sm {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mx-0-on-sm {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mx-xs-on-sm {
    margin-right: var(--pf-global--spacer--xs) !important;
    margin-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mx-sm-on-sm {
    margin-right: var(--pf-global--spacer--sm) !important;
    margin-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mx-md-on-sm {
    margin-right: var(--pf-global--spacer--md) !important;
    margin-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mx-lg-on-sm {
    margin-right: var(--pf-global--spacer--lg) !important;
    margin-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mx-xl-on-sm {
    margin-right: var(--pf-global--spacer--xl) !important;
    margin-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mx-2xl-on-sm {
    margin-right: var(--pf-global--spacer--2xl) !important;
    margin-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mx-3xl-on-sm {
    margin-right: var(--pf-global--spacer--3xl) !important;
    margin-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-mx-4xl-on-sm {
    margin-right: var(--pf-global--spacer--4xl) !important;
    margin-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-my-auto-on-sm {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-my-0-on-sm {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-my-xs-on-sm {
    margin-top: var(--pf-global--spacer--xs) !important;
    margin-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-my-sm-on-sm {
    margin-top: var(--pf-global--spacer--sm) !important;
    margin-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-my-md-on-sm {
    margin-top: var(--pf-global--spacer--md) !important;
    margin-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-my-lg-on-sm {
    margin-top: var(--pf-global--spacer--lg) !important;
    margin-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-my-xl-on-sm {
    margin-top: var(--pf-global--spacer--xl) !important;
    margin-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-my-2xl-on-sm {
    margin-top: var(--pf-global--spacer--2xl) !important;
    margin-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-my-3xl-on-sm {
    margin-top: var(--pf-global--spacer--3xl) !important;
    margin-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-my-4xl-on-sm {
    margin-top: var(--pf-global--spacer--4xl) !important;
    margin-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-p-auto-on-sm {
    padding: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-p-0-on-sm {
    padding: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-p-xs-on-sm {
    padding: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-p-sm-on-sm {
    padding: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-p-md-on-sm {
    padding: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-p-lg-on-sm {
    padding: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-p-xl-on-sm {
    padding: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-p-2xl-on-sm {
    padding: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-p-3xl-on-sm {
    padding: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-p-4xl-on-sm {
    padding: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pt-auto-on-sm {
    padding-top: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pt-0-on-sm {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pt-xs-on-sm {
    padding-top: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pt-sm-on-sm {
    padding-top: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pt-md-on-sm {
    padding-top: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pt-lg-on-sm {
    padding-top: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pt-xl-on-sm {
    padding-top: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pt-2xl-on-sm {
    padding-top: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pt-3xl-on-sm {
    padding-top: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pt-4xl-on-sm {
    padding-top: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pr-auto-on-sm {
    padding-right: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pr-0-on-sm {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pr-xs-on-sm {
    padding-right: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pr-sm-on-sm {
    padding-right: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pr-md-on-sm {
    padding-right: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pr-lg-on-sm {
    padding-right: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pr-xl-on-sm {
    padding-right: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pr-2xl-on-sm {
    padding-right: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pr-3xl-on-sm {
    padding-right: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pr-4xl-on-sm {
    padding-right: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pb-auto-on-sm {
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pb-0-on-sm {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pb-xs-on-sm {
    padding-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pb-sm-on-sm {
    padding-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pb-md-on-sm {
    padding-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pb-lg-on-sm {
    padding-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pb-xl-on-sm {
    padding-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pb-2xl-on-sm {
    padding-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pb-3xl-on-sm {
    padding-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pb-4xl-on-sm {
    padding-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pl-auto-on-sm {
    padding-left: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pl-0-on-sm {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pl-xs-on-sm {
    padding-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pl-sm-on-sm {
    padding-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pl-md-on-sm {
    padding-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pl-lg-on-sm {
    padding-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pl-xl-on-sm {
    padding-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pl-2xl-on-sm {
    padding-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pl-3xl-on-sm {
    padding-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-pl-4xl-on-sm {
    padding-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-px-auto-on-sm {
    padding-right: auto !important;
    padding-left: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-px-0-on-sm {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-px-xs-on-sm {
    padding-right: var(--pf-global--spacer--xs) !important;
    padding-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-px-sm-on-sm {
    padding-right: var(--pf-global--spacer--sm) !important;
    padding-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-px-md-on-sm {
    padding-right: var(--pf-global--spacer--md) !important;
    padding-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-px-lg-on-sm {
    padding-right: var(--pf-global--spacer--lg) !important;
    padding-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-px-xl-on-sm {
    padding-right: var(--pf-global--spacer--xl) !important;
    padding-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-px-2xl-on-sm {
    padding-right: var(--pf-global--spacer--2xl) !important;
    padding-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-px-3xl-on-sm {
    padding-right: var(--pf-global--spacer--3xl) !important;
    padding-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-px-4xl-on-sm {
    padding-right: var(--pf-global--spacer--4xl) !important;
    padding-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-py-auto-on-sm {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-py-0-on-sm {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-py-xs-on-sm {
    padding-top: var(--pf-global--spacer--xs) !important;
    padding-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-py-sm-on-sm {
    padding-top: var(--pf-global--spacer--sm) !important;
    padding-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-py-md-on-sm {
    padding-top: var(--pf-global--spacer--md) !important;
    padding-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-py-lg-on-sm {
    padding-top: var(--pf-global--spacer--lg) !important;
    padding-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-py-xl-on-sm {
    padding-top: var(--pf-global--spacer--xl) !important;
    padding-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-py-2xl-on-sm {
    padding-top: var(--pf-global--spacer--2xl) !important;
    padding-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-py-3xl-on-sm {
    padding-top: var(--pf-global--spacer--3xl) !important;
    padding-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-u-py-4xl-on-sm {
    padding-top: var(--pf-global--spacer--4xl) !important;
    padding-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-m-auto-on-md {
    margin: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-m-0-on-md {
    margin: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-m-xs-on-md {
    margin: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-m-sm-on-md {
    margin: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-m-md-on-md {
    margin: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-m-lg-on-md {
    margin: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-m-xl-on-md {
    margin: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-m-2xl-on-md {
    margin: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-m-3xl-on-md {
    margin: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-m-4xl-on-md {
    margin: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mt-auto-on-md {
    margin-top: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mt-0-on-md {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mt-xs-on-md {
    margin-top: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mt-sm-on-md {
    margin-top: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mt-md-on-md {
    margin-top: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mt-lg-on-md {
    margin-top: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mt-xl-on-md {
    margin-top: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mt-2xl-on-md {
    margin-top: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mt-3xl-on-md {
    margin-top: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mt-4xl-on-md {
    margin-top: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mr-auto-on-md {
    margin-right: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mr-0-on-md {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mr-xs-on-md {
    margin-right: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mr-sm-on-md {
    margin-right: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mr-md-on-md {
    margin-right: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mr-lg-on-md {
    margin-right: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mr-xl-on-md {
    margin-right: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mr-2xl-on-md {
    margin-right: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mr-3xl-on-md {
    margin-right: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mr-4xl-on-md {
    margin-right: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mb-auto-on-md {
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mb-0-on-md {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mb-xs-on-md {
    margin-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mb-sm-on-md {
    margin-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mb-md-on-md {
    margin-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mb-lg-on-md {
    margin-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mb-xl-on-md {
    margin-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mb-2xl-on-md {
    margin-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mb-3xl-on-md {
    margin-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mb-4xl-on-md {
    margin-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-ml-auto-on-md {
    margin-left: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-ml-0-on-md {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-ml-xs-on-md {
    margin-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-ml-sm-on-md {
    margin-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-ml-md-on-md {
    margin-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-ml-lg-on-md {
    margin-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-ml-xl-on-md {
    margin-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-ml-2xl-on-md {
    margin-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-ml-3xl-on-md {
    margin-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-ml-4xl-on-md {
    margin-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mx-auto-on-md {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mx-0-on-md {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mx-xs-on-md {
    margin-right: var(--pf-global--spacer--xs) !important;
    margin-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mx-sm-on-md {
    margin-right: var(--pf-global--spacer--sm) !important;
    margin-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mx-md-on-md {
    margin-right: var(--pf-global--spacer--md) !important;
    margin-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mx-lg-on-md {
    margin-right: var(--pf-global--spacer--lg) !important;
    margin-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mx-xl-on-md {
    margin-right: var(--pf-global--spacer--xl) !important;
    margin-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mx-2xl-on-md {
    margin-right: var(--pf-global--spacer--2xl) !important;
    margin-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mx-3xl-on-md {
    margin-right: var(--pf-global--spacer--3xl) !important;
    margin-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-mx-4xl-on-md {
    margin-right: var(--pf-global--spacer--4xl) !important;
    margin-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-my-auto-on-md {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-my-0-on-md {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-my-xs-on-md {
    margin-top: var(--pf-global--spacer--xs) !important;
    margin-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-my-sm-on-md {
    margin-top: var(--pf-global--spacer--sm) !important;
    margin-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-my-md-on-md {
    margin-top: var(--pf-global--spacer--md) !important;
    margin-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-my-lg-on-md {
    margin-top: var(--pf-global--spacer--lg) !important;
    margin-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-my-xl-on-md {
    margin-top: var(--pf-global--spacer--xl) !important;
    margin-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-my-2xl-on-md {
    margin-top: var(--pf-global--spacer--2xl) !important;
    margin-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-my-3xl-on-md {
    margin-top: var(--pf-global--spacer--3xl) !important;
    margin-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-my-4xl-on-md {
    margin-top: var(--pf-global--spacer--4xl) !important;
    margin-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-p-auto-on-md {
    padding: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-p-0-on-md {
    padding: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-p-xs-on-md {
    padding: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-p-sm-on-md {
    padding: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-p-md-on-md {
    padding: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-p-lg-on-md {
    padding: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-p-xl-on-md {
    padding: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-p-2xl-on-md {
    padding: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-p-3xl-on-md {
    padding: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-p-4xl-on-md {
    padding: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pt-auto-on-md {
    padding-top: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pt-0-on-md {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pt-xs-on-md {
    padding-top: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pt-sm-on-md {
    padding-top: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pt-md-on-md {
    padding-top: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pt-lg-on-md {
    padding-top: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pt-xl-on-md {
    padding-top: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pt-2xl-on-md {
    padding-top: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pt-3xl-on-md {
    padding-top: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pt-4xl-on-md {
    padding-top: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pr-auto-on-md {
    padding-right: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pr-0-on-md {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pr-xs-on-md {
    padding-right: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pr-sm-on-md {
    padding-right: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pr-md-on-md {
    padding-right: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pr-lg-on-md {
    padding-right: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pr-xl-on-md {
    padding-right: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pr-2xl-on-md {
    padding-right: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pr-3xl-on-md {
    padding-right: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pr-4xl-on-md {
    padding-right: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pb-auto-on-md {
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pb-0-on-md {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pb-xs-on-md {
    padding-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pb-sm-on-md {
    padding-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pb-md-on-md {
    padding-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pb-lg-on-md {
    padding-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pb-xl-on-md {
    padding-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pb-2xl-on-md {
    padding-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pb-3xl-on-md {
    padding-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pb-4xl-on-md {
    padding-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pl-auto-on-md {
    padding-left: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pl-0-on-md {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pl-xs-on-md {
    padding-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pl-sm-on-md {
    padding-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pl-md-on-md {
    padding-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pl-lg-on-md {
    padding-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pl-xl-on-md {
    padding-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pl-2xl-on-md {
    padding-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pl-3xl-on-md {
    padding-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-pl-4xl-on-md {
    padding-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-px-auto-on-md {
    padding-right: auto !important;
    padding-left: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-px-0-on-md {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-px-xs-on-md {
    padding-right: var(--pf-global--spacer--xs) !important;
    padding-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-px-sm-on-md {
    padding-right: var(--pf-global--spacer--sm) !important;
    padding-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-px-md-on-md {
    padding-right: var(--pf-global--spacer--md) !important;
    padding-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-px-lg-on-md {
    padding-right: var(--pf-global--spacer--lg) !important;
    padding-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-px-xl-on-md {
    padding-right: var(--pf-global--spacer--xl) !important;
    padding-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-px-2xl-on-md {
    padding-right: var(--pf-global--spacer--2xl) !important;
    padding-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-px-3xl-on-md {
    padding-right: var(--pf-global--spacer--3xl) !important;
    padding-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-px-4xl-on-md {
    padding-right: var(--pf-global--spacer--4xl) !important;
    padding-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-py-auto-on-md {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-py-0-on-md {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-py-xs-on-md {
    padding-top: var(--pf-global--spacer--xs) !important;
    padding-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-py-sm-on-md {
    padding-top: var(--pf-global--spacer--sm) !important;
    padding-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-py-md-on-md {
    padding-top: var(--pf-global--spacer--md) !important;
    padding-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-py-lg-on-md {
    padding-top: var(--pf-global--spacer--lg) !important;
    padding-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-py-xl-on-md {
    padding-top: var(--pf-global--spacer--xl) !important;
    padding-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-py-2xl-on-md {
    padding-top: var(--pf-global--spacer--2xl) !important;
    padding-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-py-3xl-on-md {
    padding-top: var(--pf-global--spacer--3xl) !important;
    padding-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-u-py-4xl-on-md {
    padding-top: var(--pf-global--spacer--4xl) !important;
    padding-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-m-auto-on-lg {
    margin: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-m-0-on-lg {
    margin: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-m-xs-on-lg {
    margin: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-m-sm-on-lg {
    margin: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-m-md-on-lg {
    margin: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-m-lg-on-lg {
    margin: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-m-xl-on-lg {
    margin: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-m-2xl-on-lg {
    margin: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-m-3xl-on-lg {
    margin: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-m-4xl-on-lg {
    margin: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mt-auto-on-lg {
    margin-top: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mt-0-on-lg {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mt-xs-on-lg {
    margin-top: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mt-sm-on-lg {
    margin-top: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mt-md-on-lg {
    margin-top: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mt-lg-on-lg {
    margin-top: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mt-xl-on-lg {
    margin-top: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mt-2xl-on-lg {
    margin-top: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mt-3xl-on-lg {
    margin-top: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mt-4xl-on-lg {
    margin-top: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mr-auto-on-lg {
    margin-right: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mr-0-on-lg {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mr-xs-on-lg {
    margin-right: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mr-sm-on-lg {
    margin-right: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mr-md-on-lg {
    margin-right: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mr-lg-on-lg {
    margin-right: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mr-xl-on-lg {
    margin-right: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mr-2xl-on-lg {
    margin-right: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mr-3xl-on-lg {
    margin-right: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mr-4xl-on-lg {
    margin-right: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mb-auto-on-lg {
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mb-0-on-lg {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mb-xs-on-lg {
    margin-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mb-sm-on-lg {
    margin-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mb-md-on-lg {
    margin-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mb-lg-on-lg {
    margin-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mb-xl-on-lg {
    margin-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mb-2xl-on-lg {
    margin-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mb-3xl-on-lg {
    margin-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mb-4xl-on-lg {
    margin-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-ml-auto-on-lg {
    margin-left: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-ml-0-on-lg {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-ml-xs-on-lg {
    margin-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-ml-sm-on-lg {
    margin-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-ml-md-on-lg {
    margin-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-ml-lg-on-lg {
    margin-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-ml-xl-on-lg {
    margin-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-ml-2xl-on-lg {
    margin-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-ml-3xl-on-lg {
    margin-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-ml-4xl-on-lg {
    margin-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mx-auto-on-lg {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mx-0-on-lg {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mx-xs-on-lg {
    margin-right: var(--pf-global--spacer--xs) !important;
    margin-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mx-sm-on-lg {
    margin-right: var(--pf-global--spacer--sm) !important;
    margin-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mx-md-on-lg {
    margin-right: var(--pf-global--spacer--md) !important;
    margin-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mx-lg-on-lg {
    margin-right: var(--pf-global--spacer--lg) !important;
    margin-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mx-xl-on-lg {
    margin-right: var(--pf-global--spacer--xl) !important;
    margin-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mx-2xl-on-lg {
    margin-right: var(--pf-global--spacer--2xl) !important;
    margin-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mx-3xl-on-lg {
    margin-right: var(--pf-global--spacer--3xl) !important;
    margin-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-mx-4xl-on-lg {
    margin-right: var(--pf-global--spacer--4xl) !important;
    margin-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-my-auto-on-lg {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-my-0-on-lg {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-my-xs-on-lg {
    margin-top: var(--pf-global--spacer--xs) !important;
    margin-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-my-sm-on-lg {
    margin-top: var(--pf-global--spacer--sm) !important;
    margin-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-my-md-on-lg {
    margin-top: var(--pf-global--spacer--md) !important;
    margin-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-my-lg-on-lg {
    margin-top: var(--pf-global--spacer--lg) !important;
    margin-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-my-xl-on-lg {
    margin-top: var(--pf-global--spacer--xl) !important;
    margin-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-my-2xl-on-lg {
    margin-top: var(--pf-global--spacer--2xl) !important;
    margin-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-my-3xl-on-lg {
    margin-top: var(--pf-global--spacer--3xl) !important;
    margin-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-my-4xl-on-lg {
    margin-top: var(--pf-global--spacer--4xl) !important;
    margin-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-p-auto-on-lg {
    padding: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-p-0-on-lg {
    padding: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-p-xs-on-lg {
    padding: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-p-sm-on-lg {
    padding: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-p-md-on-lg {
    padding: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-p-lg-on-lg {
    padding: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-p-xl-on-lg {
    padding: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-p-2xl-on-lg {
    padding: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-p-3xl-on-lg {
    padding: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-p-4xl-on-lg {
    padding: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pt-auto-on-lg {
    padding-top: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pt-0-on-lg {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pt-xs-on-lg {
    padding-top: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pt-sm-on-lg {
    padding-top: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pt-md-on-lg {
    padding-top: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pt-lg-on-lg {
    padding-top: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pt-xl-on-lg {
    padding-top: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pt-2xl-on-lg {
    padding-top: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pt-3xl-on-lg {
    padding-top: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pt-4xl-on-lg {
    padding-top: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pr-auto-on-lg {
    padding-right: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pr-0-on-lg {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pr-xs-on-lg {
    padding-right: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pr-sm-on-lg {
    padding-right: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pr-md-on-lg {
    padding-right: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pr-lg-on-lg {
    padding-right: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pr-xl-on-lg {
    padding-right: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pr-2xl-on-lg {
    padding-right: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pr-3xl-on-lg {
    padding-right: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pr-4xl-on-lg {
    padding-right: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pb-auto-on-lg {
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pb-0-on-lg {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pb-xs-on-lg {
    padding-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pb-sm-on-lg {
    padding-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pb-md-on-lg {
    padding-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pb-lg-on-lg {
    padding-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pb-xl-on-lg {
    padding-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pb-2xl-on-lg {
    padding-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pb-3xl-on-lg {
    padding-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pb-4xl-on-lg {
    padding-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pl-auto-on-lg {
    padding-left: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pl-0-on-lg {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pl-xs-on-lg {
    padding-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pl-sm-on-lg {
    padding-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pl-md-on-lg {
    padding-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pl-lg-on-lg {
    padding-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pl-xl-on-lg {
    padding-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pl-2xl-on-lg {
    padding-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pl-3xl-on-lg {
    padding-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-pl-4xl-on-lg {
    padding-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-px-auto-on-lg {
    padding-right: auto !important;
    padding-left: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-px-0-on-lg {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-px-xs-on-lg {
    padding-right: var(--pf-global--spacer--xs) !important;
    padding-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-px-sm-on-lg {
    padding-right: var(--pf-global--spacer--sm) !important;
    padding-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-px-md-on-lg {
    padding-right: var(--pf-global--spacer--md) !important;
    padding-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-px-lg-on-lg {
    padding-right: var(--pf-global--spacer--lg) !important;
    padding-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-px-xl-on-lg {
    padding-right: var(--pf-global--spacer--xl) !important;
    padding-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-px-2xl-on-lg {
    padding-right: var(--pf-global--spacer--2xl) !important;
    padding-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-px-3xl-on-lg {
    padding-right: var(--pf-global--spacer--3xl) !important;
    padding-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-px-4xl-on-lg {
    padding-right: var(--pf-global--spacer--4xl) !important;
    padding-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-py-auto-on-lg {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-py-0-on-lg {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-py-xs-on-lg {
    padding-top: var(--pf-global--spacer--xs) !important;
    padding-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-py-sm-on-lg {
    padding-top: var(--pf-global--spacer--sm) !important;
    padding-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-py-md-on-lg {
    padding-top: var(--pf-global--spacer--md) !important;
    padding-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-py-lg-on-lg {
    padding-top: var(--pf-global--spacer--lg) !important;
    padding-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-py-xl-on-lg {
    padding-top: var(--pf-global--spacer--xl) !important;
    padding-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-py-2xl-on-lg {
    padding-top: var(--pf-global--spacer--2xl) !important;
    padding-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-py-3xl-on-lg {
    padding-top: var(--pf-global--spacer--3xl) !important;
    padding-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-u-py-4xl-on-lg {
    padding-top: var(--pf-global--spacer--4xl) !important;
    padding-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-m-auto-on-xl {
    margin: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-m-0-on-xl {
    margin: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-m-xs-on-xl {
    margin: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-m-sm-on-xl {
    margin: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-m-md-on-xl {
    margin: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-m-lg-on-xl {
    margin: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-m-xl-on-xl {
    margin: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-m-2xl-on-xl {
    margin: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-m-3xl-on-xl {
    margin: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-m-4xl-on-xl {
    margin: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mt-auto-on-xl {
    margin-top: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mt-0-on-xl {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mt-xs-on-xl {
    margin-top: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mt-sm-on-xl {
    margin-top: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mt-md-on-xl {
    margin-top: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mt-lg-on-xl {
    margin-top: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mt-xl-on-xl {
    margin-top: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mt-2xl-on-xl {
    margin-top: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mt-3xl-on-xl {
    margin-top: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mt-4xl-on-xl {
    margin-top: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mr-auto-on-xl {
    margin-right: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mr-0-on-xl {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mr-xs-on-xl {
    margin-right: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mr-sm-on-xl {
    margin-right: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mr-md-on-xl {
    margin-right: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mr-lg-on-xl {
    margin-right: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mr-xl-on-xl {
    margin-right: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mr-2xl-on-xl {
    margin-right: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mr-3xl-on-xl {
    margin-right: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mr-4xl-on-xl {
    margin-right: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mb-auto-on-xl {
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mb-0-on-xl {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mb-xs-on-xl {
    margin-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mb-sm-on-xl {
    margin-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mb-md-on-xl {
    margin-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mb-lg-on-xl {
    margin-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mb-xl-on-xl {
    margin-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mb-2xl-on-xl {
    margin-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mb-3xl-on-xl {
    margin-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mb-4xl-on-xl {
    margin-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-ml-auto-on-xl {
    margin-left: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-ml-0-on-xl {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-ml-xs-on-xl {
    margin-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-ml-sm-on-xl {
    margin-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-ml-md-on-xl {
    margin-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-ml-lg-on-xl {
    margin-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-ml-xl-on-xl {
    margin-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-ml-2xl-on-xl {
    margin-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-ml-3xl-on-xl {
    margin-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-ml-4xl-on-xl {
    margin-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mx-auto-on-xl {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mx-0-on-xl {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mx-xs-on-xl {
    margin-right: var(--pf-global--spacer--xs) !important;
    margin-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mx-sm-on-xl {
    margin-right: var(--pf-global--spacer--sm) !important;
    margin-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mx-md-on-xl {
    margin-right: var(--pf-global--spacer--md) !important;
    margin-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mx-lg-on-xl {
    margin-right: var(--pf-global--spacer--lg) !important;
    margin-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mx-xl-on-xl {
    margin-right: var(--pf-global--spacer--xl) !important;
    margin-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mx-2xl-on-xl {
    margin-right: var(--pf-global--spacer--2xl) !important;
    margin-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mx-3xl-on-xl {
    margin-right: var(--pf-global--spacer--3xl) !important;
    margin-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-mx-4xl-on-xl {
    margin-right: var(--pf-global--spacer--4xl) !important;
    margin-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-my-auto-on-xl {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-my-0-on-xl {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-my-xs-on-xl {
    margin-top: var(--pf-global--spacer--xs) !important;
    margin-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-my-sm-on-xl {
    margin-top: var(--pf-global--spacer--sm) !important;
    margin-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-my-md-on-xl {
    margin-top: var(--pf-global--spacer--md) !important;
    margin-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-my-lg-on-xl {
    margin-top: var(--pf-global--spacer--lg) !important;
    margin-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-my-xl-on-xl {
    margin-top: var(--pf-global--spacer--xl) !important;
    margin-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-my-2xl-on-xl {
    margin-top: var(--pf-global--spacer--2xl) !important;
    margin-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-my-3xl-on-xl {
    margin-top: var(--pf-global--spacer--3xl) !important;
    margin-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-my-4xl-on-xl {
    margin-top: var(--pf-global--spacer--4xl) !important;
    margin-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-p-auto-on-xl {
    padding: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-p-0-on-xl {
    padding: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-p-xs-on-xl {
    padding: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-p-sm-on-xl {
    padding: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-p-md-on-xl {
    padding: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-p-lg-on-xl {
    padding: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-p-xl-on-xl {
    padding: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-p-2xl-on-xl {
    padding: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-p-3xl-on-xl {
    padding: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-p-4xl-on-xl {
    padding: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pt-auto-on-xl {
    padding-top: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pt-0-on-xl {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pt-xs-on-xl {
    padding-top: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pt-sm-on-xl {
    padding-top: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pt-md-on-xl {
    padding-top: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pt-lg-on-xl {
    padding-top: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pt-xl-on-xl {
    padding-top: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pt-2xl-on-xl {
    padding-top: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pt-3xl-on-xl {
    padding-top: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pt-4xl-on-xl {
    padding-top: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pr-auto-on-xl {
    padding-right: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pr-0-on-xl {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pr-xs-on-xl {
    padding-right: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pr-sm-on-xl {
    padding-right: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pr-md-on-xl {
    padding-right: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pr-lg-on-xl {
    padding-right: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pr-xl-on-xl {
    padding-right: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pr-2xl-on-xl {
    padding-right: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pr-3xl-on-xl {
    padding-right: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pr-4xl-on-xl {
    padding-right: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pb-auto-on-xl {
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pb-0-on-xl {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pb-xs-on-xl {
    padding-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pb-sm-on-xl {
    padding-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pb-md-on-xl {
    padding-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pb-lg-on-xl {
    padding-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pb-xl-on-xl {
    padding-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pb-2xl-on-xl {
    padding-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pb-3xl-on-xl {
    padding-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pb-4xl-on-xl {
    padding-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pl-auto-on-xl {
    padding-left: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pl-0-on-xl {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pl-xs-on-xl {
    padding-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pl-sm-on-xl {
    padding-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pl-md-on-xl {
    padding-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pl-lg-on-xl {
    padding-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pl-xl-on-xl {
    padding-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pl-2xl-on-xl {
    padding-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pl-3xl-on-xl {
    padding-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-pl-4xl-on-xl {
    padding-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-px-auto-on-xl {
    padding-right: auto !important;
    padding-left: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-px-0-on-xl {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-px-xs-on-xl {
    padding-right: var(--pf-global--spacer--xs) !important;
    padding-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-px-sm-on-xl {
    padding-right: var(--pf-global--spacer--sm) !important;
    padding-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-px-md-on-xl {
    padding-right: var(--pf-global--spacer--md) !important;
    padding-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-px-lg-on-xl {
    padding-right: var(--pf-global--spacer--lg) !important;
    padding-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-px-xl-on-xl {
    padding-right: var(--pf-global--spacer--xl) !important;
    padding-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-px-2xl-on-xl {
    padding-right: var(--pf-global--spacer--2xl) !important;
    padding-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-px-3xl-on-xl {
    padding-right: var(--pf-global--spacer--3xl) !important;
    padding-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-px-4xl-on-xl {
    padding-right: var(--pf-global--spacer--4xl) !important;
    padding-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-py-auto-on-xl {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-py-0-on-xl {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-py-xs-on-xl {
    padding-top: var(--pf-global--spacer--xs) !important;
    padding-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-py-sm-on-xl {
    padding-top: var(--pf-global--spacer--sm) !important;
    padding-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-py-md-on-xl {
    padding-top: var(--pf-global--spacer--md) !important;
    padding-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-py-lg-on-xl {
    padding-top: var(--pf-global--spacer--lg) !important;
    padding-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-py-xl-on-xl {
    padding-top: var(--pf-global--spacer--xl) !important;
    padding-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-py-2xl-on-xl {
    padding-top: var(--pf-global--spacer--2xl) !important;
    padding-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-py-3xl-on-xl {
    padding-top: var(--pf-global--spacer--3xl) !important;
    padding-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-u-py-4xl-on-xl {
    padding-top: var(--pf-global--spacer--4xl) !important;
    padding-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-m-auto-on-2xl {
    margin: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-m-0-on-2xl {
    margin: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-m-xs-on-2xl {
    margin: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-m-sm-on-2xl {
    margin: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-m-md-on-2xl {
    margin: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-m-lg-on-2xl {
    margin: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-m-xl-on-2xl {
    margin: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-m-2xl-on-2xl {
    margin: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-m-3xl-on-2xl {
    margin: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-m-4xl-on-2xl {
    margin: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mt-auto-on-2xl {
    margin-top: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mt-0-on-2xl {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mt-xs-on-2xl {
    margin-top: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mt-sm-on-2xl {
    margin-top: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mt-md-on-2xl {
    margin-top: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mt-lg-on-2xl {
    margin-top: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mt-xl-on-2xl {
    margin-top: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mt-2xl-on-2xl {
    margin-top: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mt-3xl-on-2xl {
    margin-top: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mt-4xl-on-2xl {
    margin-top: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mr-auto-on-2xl {
    margin-right: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mr-0-on-2xl {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mr-xs-on-2xl {
    margin-right: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mr-sm-on-2xl {
    margin-right: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mr-md-on-2xl {
    margin-right: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mr-lg-on-2xl {
    margin-right: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mr-xl-on-2xl {
    margin-right: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mr-2xl-on-2xl {
    margin-right: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mr-3xl-on-2xl {
    margin-right: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mr-4xl-on-2xl {
    margin-right: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mb-auto-on-2xl {
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mb-0-on-2xl {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mb-xs-on-2xl {
    margin-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mb-sm-on-2xl {
    margin-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mb-md-on-2xl {
    margin-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mb-lg-on-2xl {
    margin-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mb-xl-on-2xl {
    margin-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mb-2xl-on-2xl {
    margin-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mb-3xl-on-2xl {
    margin-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mb-4xl-on-2xl {
    margin-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-ml-auto-on-2xl {
    margin-left: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-ml-0-on-2xl {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-ml-xs-on-2xl {
    margin-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-ml-sm-on-2xl {
    margin-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-ml-md-on-2xl {
    margin-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-ml-lg-on-2xl {
    margin-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-ml-xl-on-2xl {
    margin-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-ml-2xl-on-2xl {
    margin-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-ml-3xl-on-2xl {
    margin-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-ml-4xl-on-2xl {
    margin-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mx-auto-on-2xl {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mx-0-on-2xl {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mx-xs-on-2xl {
    margin-right: var(--pf-global--spacer--xs) !important;
    margin-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mx-sm-on-2xl {
    margin-right: var(--pf-global--spacer--sm) !important;
    margin-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mx-md-on-2xl {
    margin-right: var(--pf-global--spacer--md) !important;
    margin-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mx-lg-on-2xl {
    margin-right: var(--pf-global--spacer--lg) !important;
    margin-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mx-xl-on-2xl {
    margin-right: var(--pf-global--spacer--xl) !important;
    margin-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mx-2xl-on-2xl {
    margin-right: var(--pf-global--spacer--2xl) !important;
    margin-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mx-3xl-on-2xl {
    margin-right: var(--pf-global--spacer--3xl) !important;
    margin-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-mx-4xl-on-2xl {
    margin-right: var(--pf-global--spacer--4xl) !important;
    margin-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-my-auto-on-2xl {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-my-0-on-2xl {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-my-xs-on-2xl {
    margin-top: var(--pf-global--spacer--xs) !important;
    margin-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-my-sm-on-2xl {
    margin-top: var(--pf-global--spacer--sm) !important;
    margin-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-my-md-on-2xl {
    margin-top: var(--pf-global--spacer--md) !important;
    margin-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-my-lg-on-2xl {
    margin-top: var(--pf-global--spacer--lg) !important;
    margin-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-my-xl-on-2xl {
    margin-top: var(--pf-global--spacer--xl) !important;
    margin-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-my-2xl-on-2xl {
    margin-top: var(--pf-global--spacer--2xl) !important;
    margin-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-my-3xl-on-2xl {
    margin-top: var(--pf-global--spacer--3xl) !important;
    margin-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-my-4xl-on-2xl {
    margin-top: var(--pf-global--spacer--4xl) !important;
    margin-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-p-auto-on-2xl {
    padding: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-p-0-on-2xl {
    padding: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-p-xs-on-2xl {
    padding: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-p-sm-on-2xl {
    padding: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-p-md-on-2xl {
    padding: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-p-lg-on-2xl {
    padding: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-p-xl-on-2xl {
    padding: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-p-2xl-on-2xl {
    padding: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-p-3xl-on-2xl {
    padding: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-p-4xl-on-2xl {
    padding: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pt-auto-on-2xl {
    padding-top: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pt-0-on-2xl {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pt-xs-on-2xl {
    padding-top: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pt-sm-on-2xl {
    padding-top: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pt-md-on-2xl {
    padding-top: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pt-lg-on-2xl {
    padding-top: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pt-xl-on-2xl {
    padding-top: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pt-2xl-on-2xl {
    padding-top: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pt-3xl-on-2xl {
    padding-top: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pt-4xl-on-2xl {
    padding-top: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pr-auto-on-2xl {
    padding-right: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pr-0-on-2xl {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pr-xs-on-2xl {
    padding-right: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pr-sm-on-2xl {
    padding-right: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pr-md-on-2xl {
    padding-right: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pr-lg-on-2xl {
    padding-right: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pr-xl-on-2xl {
    padding-right: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pr-2xl-on-2xl {
    padding-right: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pr-3xl-on-2xl {
    padding-right: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pr-4xl-on-2xl {
    padding-right: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pb-auto-on-2xl {
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pb-0-on-2xl {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pb-xs-on-2xl {
    padding-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pb-sm-on-2xl {
    padding-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pb-md-on-2xl {
    padding-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pb-lg-on-2xl {
    padding-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pb-xl-on-2xl {
    padding-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pb-2xl-on-2xl {
    padding-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pb-3xl-on-2xl {
    padding-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pb-4xl-on-2xl {
    padding-bottom: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pl-auto-on-2xl {
    padding-left: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pl-0-on-2xl {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pl-xs-on-2xl {
    padding-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pl-sm-on-2xl {
    padding-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pl-md-on-2xl {
    padding-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pl-lg-on-2xl {
    padding-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pl-xl-on-2xl {
    padding-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pl-2xl-on-2xl {
    padding-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pl-3xl-on-2xl {
    padding-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-pl-4xl-on-2xl {
    padding-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-px-auto-on-2xl {
    padding-right: auto !important;
    padding-left: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-px-0-on-2xl {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-px-xs-on-2xl {
    padding-right: var(--pf-global--spacer--xs) !important;
    padding-left: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-px-sm-on-2xl {
    padding-right: var(--pf-global--spacer--sm) !important;
    padding-left: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-px-md-on-2xl {
    padding-right: var(--pf-global--spacer--md) !important;
    padding-left: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-px-lg-on-2xl {
    padding-right: var(--pf-global--spacer--lg) !important;
    padding-left: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-px-xl-on-2xl {
    padding-right: var(--pf-global--spacer--xl) !important;
    padding-left: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-px-2xl-on-2xl {
    padding-right: var(--pf-global--spacer--2xl) !important;
    padding-left: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-px-3xl-on-2xl {
    padding-right: var(--pf-global--spacer--3xl) !important;
    padding-left: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-px-4xl-on-2xl {
    padding-right: var(--pf-global--spacer--4xl) !important;
    padding-left: var(--pf-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-py-auto-on-2xl {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-py-0-on-2xl {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-py-xs-on-2xl {
    padding-top: var(--pf-global--spacer--xs) !important;
    padding-bottom: var(--pf-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-py-sm-on-2xl {
    padding-top: var(--pf-global--spacer--sm) !important;
    padding-bottom: var(--pf-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-py-md-on-2xl {
    padding-top: var(--pf-global--spacer--md) !important;
    padding-bottom: var(--pf-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-py-lg-on-2xl {
    padding-top: var(--pf-global--spacer--lg) !important;
    padding-bottom: var(--pf-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-py-xl-on-2xl {
    padding-top: var(--pf-global--spacer--xl) !important;
    padding-bottom: var(--pf-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-py-2xl-on-2xl {
    padding-top: var(--pf-global--spacer--2xl) !important;
    padding-bottom: var(--pf-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-py-3xl-on-2xl {
    padding-top: var(--pf-global--spacer--3xl) !important;
    padding-bottom: var(--pf-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-u-py-4xl-on-2xl {
    padding-top: var(--pf-global--spacer--4xl) !important;
    padding-bottom: var(--pf-global--spacer--4xl) !important;
  }
}